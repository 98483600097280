<template>
  <!-- 讲师详情 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)">
      <template slot="extra">
        <a-button>
          离编/在编
        </a-button>
      </template>
    </a-page-header>
    <div class="main-content">
      <div class="body" v-if="info">
        <div class="body-left">
          <div class="user">
            <div class="avatar">
              <img class="img" :src="info.avatarUrl" />
            </div>
            <div class="name">{{ info.realName }}</div>
            <a-tag color="green" v-if="info.status === 1">
              门店状态：{{ statuText[info.status] }}
            </a-tag>
            <a-tag v-else>门店状态：{{ statuText[info.status] }}</a-tag>
          </div>
        </div>

        <div class="body-right">
          <div class="text-content">
            <div class="content-item">
              <div class="content-item-header">
                <div class="title">门店信息</div>
              </div>
              <div class="content-sub-title">概述</div>

              <div class="ul ul-1">
                <div class="li">
                  <span class="label">门店名称：</span>
                  <span>{{ info.storename }}</span>
                </div>
                <div class="li">
                  <span class="label">使用者姓名：</span>
                  <span>{{ info.username }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">系统账号：</span>
                  <span>{{ info.systemNum }}</span>
                </div>
                <div class="li">
                  <span class="label">创建时间：</span>
                  <span>{{ info.createAt }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li ">
                  <span class="label">详细地址：</span>
                  <span>{{ info.address }}</span>
                </div>
                <div class="li ">
                  <span class="label">绑定的地区：</span>
                  <span>{{ info.businessAddressName.address }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="id-card-content">
            <div class="content-sub-title">
              <span>实名信息</span>
              <a-button type="link">
                更新
              </a-button>
            </div>
            <div class="content-item content-item-flex id-card">
              <div class="id-card-img">
                <div class="item">
                  <img class="img" :src="info.IDcardFrontUrl" />
                  <div class="text">身份证国徽面</div>
                </div>
                <div class="item item-2">
                  <img class="img" :src="info.IDcardBackUrl" />
                  <div class="text">身份证人像面</div>
                </div>
              </div>

              <a-divider class="divider-vertical" type="vertical" />

              <div class="id-card-text">
                <div class="text text-1">身份证号码：{{ info.IDcardNum }}</div>
                <div class="text">有效期：{{ info.IDcardEndTime }}</div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let lectorId = ''
export default {
  data() {
    return {
      title: '',
      info: null,
      statuText: {
        1: '启用',
        0: '禁用'
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    lectorId = query.id
    // const type = query.type
    // if (type === '1') {
      this.getStoreInfo()
    // } 
  },
  methods: {
    getStoreInfo() {
      // 获取培训理论讲师详情
      const data = {
        id: lectorId
      }
    
      this.$axios.getStoreInfo(data).then((res) => {
        this.info = res.data.data
          console.log("fdfdfdfd",this.info )
      })
    },
    // getTrainingInfo() {
    //   // 获取培训实操讲师详情
    //   const data = {
    //     id: lectorId
    //   }
    //   this.$axios.getTrainingInfo(data).then((res) => {
    //     this.info = res.data.data
    //   })
    // }
  }
}
</script>
<style scoped>
.body {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.body-left,
.text-content,
.id-card-content {
  background-color: #fff;
  border-radius: 2px;
}

.body-left {
  width: 238px;
  height: 100%;
  padding: 32px;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.avatar {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 3px;
  margin: 0 auto;
}

.name {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  margin: 8px 0;
}

.body-right {
  flex: 1;
  margin-left: 24px;
}

.text-content,
.id-card-content {
  padding: 20px 32px 24px 32px;
}

.id-card-content {
  margin-top: 16px;
}

.content-item-flex {
  display: flex;
  align-items: center;
}

.content-item-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-item-header .title {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.content-sub-title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.ul-1 {
  margin-top: 16px;
}

.ul:not(:last-child) {
  margin-bottom: 16px;
}

.ul .li {
  width: 240px;
  display: inline-block;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ul .li:not(:last-child) {
  margin-right: 46px;
}

.ul .label {
  font-weight: bold;
}

.ul .address {
  width: auto;
}

.area {
  margin-left: 56px;
}

.id-card {
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 16px;
}

.id-card-img {
  display: flex;
}

.id-card-img .item {
  width: 160px;
  height: 90px;
}

.id-card-img .item-2 {
  margin-left: 24px;
}

.id-card-img .text {
  text-align: center;
  line-height: 22px;
  margin-top: 8px;
}

.divider-vertical {
  height: 100px;
  margin: 10px 32px 10px 32px;
}

.id-card-text .text-1 {
  margin: 30px 0 16px 0;
}
</style>
